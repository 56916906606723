import styled from 'styled-components';
import { color } from '../../styles/theme';
import { WithUnderline } from '../../styles/link';

const Container = styled.div`
  margin-bottom: 60px;
`;

const Title = styled.h2`
  font-size: 33px;
  line-height: 1.3;
  color: ${color('white')};
`;

const Text = styled.p`
  font-size: 16px;
  line-height: 1.5;
  color: ${color('white')};
`;

const Phone = styled(WithUnderline)`
  display: inline-block;
  margin-bottom: 20px;
  font-weight: bold;
  font-size: 33px;
  line-height: 1.3;
  color: ${color('lemon')};
  text-decoration: none;
`;

export {
  Text,
  Title,
  Phone,
  Container,
};
