import React, { useState } from 'react';
import styled from 'styled-components';
import { useForm } from 'react-hook-form';
import { color } from '../../styles/theme';
import { InputGroup, Button } from '../../styles/form';
import arrow from '../../images/arrow-blue.svg';
import Input from './Input';
import Textarea from './Textarea';

const FormContainer = styled.div`
  position: relative;
  max-width: 550px;
  min-height: 245px;
`;

const FormStyled = styled.form`
  display: flow-root;
  margin-bottom: 0;
`;

const Message = styled.p`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  max-width: 315px;
  text-align: center;
  font-weight: bold;
  line-height: 1.8;
  color: ${({ colors }) => colors === 'success' ? color('turquoise-blue') : 'crimson'};
`;

const submitStatusTypes = {
  success: {
    type: 'success',
    message: 'Thank you for emailing New Normal.\n We\'ll get right back to you.',
  },
  error: {
    type: 'error',
    message: 'Something went wrong. Please try again.',
  },
};

const ContactForm = () => {
  const [submitStatus, setSubmitStatus] = useState({});
  const {
    formState,
    register,
    handleSubmit,
  } = useForm();

  const handleFormSubmit = async (values) => {
    try {
      await fetch('/', {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: new URLSearchParams(values).toString(),
      });
      setSubmitStatus(submitStatusTypes.success);
    } catch {
      setSubmitStatus(submitStatusTypes.error);
    }
  };

  return (
    <FormContainer>
      {formState.isSubmitSuccessful
        ? (
          <Message colors={submitStatus.type}>{submitStatus.message}</Message>)
        : (
          <FormStyled
            name="contact"
            method="post"
            data-netlify="true"
            data-netlify-honeypot="bot-field"
            onSubmit={handleSubmit(handleFormSubmit)}
          >
            <p className="visually-hidden">
              <label>Don’t fill this out if you&apos;re human: <input type="hidden" name="bot-field" ref={register} /></label>
            </p>
            <input type="hidden" name="form-name" value="contact" ref={register} />
            <InputGroup>
              <Input
                type="text"
                name="name"
                placeholder="Your Name*"
                css={{
                  '@media (min-width: 768px)': {
                    marginRight: '15px',
                    maxWidth: '230px',
                  },
                }}
                inputRef={register({ required: true })}
              />
              <Input
                type="email"
                name="email"
                placeholder="Email Address*"
                inputRef={register({ required: true })}
              />
            </InputGroup>
            <Input
              type="text"
              name="subject"
              placeholder="Subject*"
              inputRef={register({ required: true })}
            />
            <Textarea
              name="message"
              placeholder="Message*"
              inputRef={register({ required: true })}
            />
            <Button as="button" type="submit" id="submitFormButton">
              <span>Let’s build something</span>
              <img src={arrow} alt="" />
            </Button>
          </FormStyled>
        )}
    </FormContainer>
  );
};

export default ContactForm;
