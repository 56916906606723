import styled from 'styled-components';

const Wrapper = styled.section`
  @media (min-width: 768px) {
    display: flex;
  }
  
  @media (min-width: 1024px) {
    min-height: 770px;
  }
  
  @media (min-width: 1920px) {
   min-height: 1070px; 
  }
`;

const Container = styled.div` 
  @media (min-width: 768px) {
    flex-basis: 50%;
  }
`;

export { Wrapper, Container };
