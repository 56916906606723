import styled from 'styled-components';
import { color, gray } from './theme';
import { WithUnderline } from './link';

const placeholder = `
  width: 100%;
  
  &::-webkit-input-placeholder {
    font-size: 12px;
    color: ${gray(500)};
  }
  
  &::-moz-placeholder {
    font-size: 12px;
    color: ${gray(500)};
  }
  
  &:-ms-input-placeholder {
    font-size: 12px;
    color: ${gray(500)};
  }
  
  &:-moz-placeholder {
    font-size: 12px;
    color: ${gray(500)};
  }
`;

const InputGroup = styled.div`
  @media (min-width: 768px) {
    display: flex;
    
    
    .input-field {
      flex: 1 1;
      flex-basis: 50%;
    }
  }
`;

const Input = styled.input`
  margin-bottom: 15px;
  padding: 10px 15px;
  border: 1px solid transparent;
  -webkit-border-radius: 0;
  font-size: 14px;
  color: ${gray(500)};
  background-color: ${color('white')};
  ${placeholder};
  
  &:focus {
    border-color: ${color('turquoise-blue')};
    box-shadow: 0 0 5px ${color('turquoise-blue')};
  }
  
  &.error {
    border-color: crimson;
  }
`;

const Textarea = styled.textarea`
  margin-bottom: 25px;
  padding: 10px 15px;
  border: 1px solid transparent;
  -webkit-border-radius: 0;
  height: 80px;
  font-size: 14px;
  color: ${gray(500)};
  resize: none;
  ${placeholder};
  
  &:focus {
    border-color: ${color('turquoise-blue')};
    box-shadow: 0 0 5px ${color('turquoise-blue')};
  }
  
  &.error {
    border-color: crimson;
  }
`;

const Button = styled(WithUnderline)`
  padding: 0 0 5px;
  border: 0;
  font-weight: bold;
  font-size: 24px;
  color: ${color('turquoise-blue')};
  background-color: transparent;
  float: right;
  cursor: pointer;
  
  img {
    position: relative;
    top: .125em;
    margin-left: 10px;
    width: 1em;
    height: 1em;
  }
`;

export {
  Input,
  InputGroup,
  Textarea,
  Button,
};
