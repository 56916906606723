import React from 'react';
import cx from 'clsx';
import { Input as InputStyled } from '../../styles/form';

const Input = ({
  className,
  inputClassName,
  error,
  css,
  placeholder,
  inputRef,
  ...props
}) => (
  <div className={cx('input-field', className)} css={css}>
    <label>
      {placeholder && <span className="visually-hidden">{placeholder}</span>}
      <InputStyled
        {...props}
        ref={inputRef}
        placeholder={placeholder}
        className={cx({ error }, inputClassName)}
      />
    </label>
  </div>
);

export default Input;
